<template>
  <div>
    <v-card
      class="secondary card-radius"
      max-width="985"
      flat
    >
      <v-card-text>
        <v-img
          :src="require('../../assets/diet-pro-img.svg')"
          width="80"
          contain
          class="mx-auto mt-n12"
        />
      </v-card-text>
      <v-row>
        <v-col :cols="$vuetify.breakpoint.smAndDown ?
          '12' : '7'">
          <v-row>
            <v-col cols="3" v-if="!$vuetify.breakpoint.smAndDown"></v-col>
            <v-col :cols="$vuetify.breakpoint.smAndDown ?
            '12' : '9'">
                <p class="mx-2">{{ $t('health.dislikes') }}</p>
              <v-text-field
                :placeholder="$t('health.searchDislikes')"
                class="white mx-2 black--text custom-field"
                rounded
                outlined
                dense
                v-model="search"
                hide-details=""
                append-icon="mdi-magnify"
              ></v-text-field>
            </v-col>
            <v-col cols="3" v-if="!$vuetify.breakpoint.smAndDown"></v-col>
            <v-col :cols="$vuetify.breakpoint.smAndDown ?
            '12' : '9'">
              <v-card
                class="white scroll mx-2 card-radius card-custom-horizantal-scroll"
                flat
                height="170"
              >
                <v-row >
                  <v-col cols="12" v-for="(option, index) in options" :key="index" class="py-0 px-6">

                <v-checkbox
                  @change="chooseOptions(option)"
                  v-model="selectedOptions[index]"
                  class=" mt-1"
                  :label="$i18n.locale === 'en' ? option.nameEn : option.nameAr"
                  :value="option"
                  color="grey"
                  dense
                  hide-details
                ></v-checkbox>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
        <v-col :cols="$vuetify.breakpoint.smAndDown ?
          '12' : '5'">
            <v-card flat tile color="transparent" max-height="290" class="overflow-y-auto" width="100%">
          <v-row class="mx-0">
              <div v-for="(i, index) in checkboxes" :key="index"  class="ma-2">
                <v-chip
                  v-if="i"
                  close
                  @click:close="removeOption(index, i)"
                  color="white black--text"
                >
                  {{ $i18n.locale === 'en' ?
                    i.nameEn : i.nameAr }}
                </v-chip>
              </div>
          </v-row>
            </v-card>
        </v-col>
      </v-row>
      <v-layout justify-center>
        <v-btn
          depressed
          rounded
          color="primary custom-font"
          class="black--text font-weight-light mb-12"
          :width="$vuetify.breakpoint.xs ? 250 : 315"
          type="submit"
          @click="getSelectedDislikes"
          :loading="btn_loading"
          >{{ $t('health.done') }}</v-btn
        >
      </v-layout>
    </v-card>
  </div>
</template>

<script>
export default {
  data () {
    return {
      search: '',
      selectedOptions: [],
      btn_loading: false,
      checkbox: [],
      checkboxes: [],
      options: []
    }
  },
  watch: {
    search(val) {
      this.$apollo.queries.getDislikes.refetch({
        input: {
          limit: 0,
          skip: 0
        },
        searchKey: val,
      });
    }
  },
  apollo: {
    getDislikes () {
      return {
        query: require("@/health/api/queries/dislikes_query.graphql"),
        variables: {
          input: {
            limit: 0,
            skip: 0
          },
          searchKey: this.search
        },
        result({ data }) {
          // , loading, networkStatus, refetch
          this.options = data.getAllDislikableIngredients.ingredients
        }
      }
    }
  },
  methods: {
    chooseOptions (option) {
      this.checkboxes.push(option)
      this.checkboxes.forEach((x, index) => {
        if (!this.selectedOptions.includes(x))
          this.checkboxes.splice(index, 1)
      })
    },
    removeOption (index, i) {
      this.checkboxes.splice(index, 1)
      const selectedIndex = this.selectedOptions.findIndex( (element) => {
        if (element) return element._id === i._id
      })
      this.selectedOptions.splice(selectedIndex, 1, null)
    },
    getSelectedDislikes () {
      this.$root.$emit('selectedDislikes', this.checkboxes)
    }
  }
}
</script>

<style scoped>
.scroll::-webkit-scrollbar {
  width: 12px;
}

.scroll::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 3px rgba(0,0,0,0.3); 
  border-radius: 20px;
}

.scroll::-webkit-scrollbar-thumb {
  border-radius: 20px;
  -webkit-box-shadow: inset 0 0 3px rgba(0,0,0,0.5); 
}
</style>